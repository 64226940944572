@import 'react-datepicker/dist/react-datepicker.css';

@font-face {
  font-family: 'Articulat CF';
  font-display: swap;
  src: local('Articulat CF'),
    url('./../public/fonts/ArticulatCF/ArticulatCF-Bold.woff2')
      format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Articulat CF';
  font-display: swap;
  src: local('Articulat CF'),
    url('./../public/fonts/ArticulatCF/ArticulatCF-DemiBold.woff2')
      format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Articulat CF';
  font-display: swap;
  src: local('Articulat CF'),
    url('./../public/fonts/ArticulatCF/ArticulatCF-Medium.woff2')
      format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Articulat CF';
  font-display: swap;
  src: local('Articulat CF'),
    url('./../public/fonts/ArticulatCF/ArticulatCF-Regular.woff2')
      format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Articulat CF';
  font-display: swap;
  src: local('Articulat CF'),
    url('./../public/fonts/ArticulatCF/ArticulatCF-Normal.woff2')
      format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Articulat CF';
  font-display: swap;
  src: local('Articulat CF'),
    url('./../public/fonts/ArticulatCF/ArticulatCF-Light.woff2')
      format('truetype');
  font-weight: 300;
}

:root {
  --border-radius: 0.5rem;
  /* COLORS */
  --primary-brand: #ff6838;
  --primary-brand-hover: #ff9d7e;
  --Primary-Dark: #2e2e2e;
  --Light-BG-Gray: #efeae0;
  --Ollie-Green: #00c377;
  --Ollie-Blue: #389efc;
  --secondary-dark: #3f3f3f;
  --Primary-Gray: #94928d;
  --Secondary-Gray: #a6a6a6;
  --subtext-blue-gray: #6a7381;
  --Border: #dcd8cf;
  --error-color: #ff5b5b;
  --success-color: #40c13d;
  --white: #ffffff;
  --brand-gradient: linear-gradient(
    45deg,
    rgba(255, 89, 36, 1) 0%,
    rgba(255, 123, 81, 1) 100%
  );
  --selection-color: #ff6838;
  --light-bg: #f9f8f7fd;
  --container-width: 51.25rem;
  --global-container-width: 58.5rem;
  --Eggshell: #fbf6ec;

  --background-color: #f2f3f5;

  --header-height: 3.75rem;

  font-size: 16px;

  // @media (max-width: 400px) or ((max-height: 400px) and (orientation: landscape)) {
  //   font-size: 12px;
  // }

  // @media ((min-height: 401px) and (max-height: 600px) and (orientation: landscape)) or ((min-width: 401px) and (max-width: 600px)) {
  //   font-size: 14px;
  // }

  // Toasts theme configuration
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: var(--success-color);
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: var(--error-color);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  .Toastify__toast {
    box-shadow: none;
    overflow: visible;
    // border-left: 6px solid;
    // border-bottom: 6px solid;
    // border-color: var(--primary-brand);
    position: relative;
    border-radius: var(--border-radius);
    border: 2px solid var(--Primary-Dark);
  }

  .Toastify__toast-body {
    display: flex;
    justify-content: center;
    text-align: center;

    font-family: 'Articulat CF';
    font-weight: 700;
    font-style: normal;
    font-size: 0.9rem;

    color: var(--Primary-Dark);
  }

  .Toastify__toast-icon {
    display: none;
  }

  // .Toastify__toast::after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0.3rem;
  //   bottom: -0.3rem;
  //   left: -0.3rem;
  //   right: 0.3rem;
  //   background-color: var(--primary-brand);
  //   border-radius: var(--border-radius);
  //   border: 2px solid var(--Primary-Dark);
  //   z-index: -1;
  // }
}

html {
  & ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background: var(--Primary-Gray, #8e8d88);
  }
}

body {
  background: var(--Eggshell);
}

::selection {
  color: white;
  background-color: var(--selection-color);
}

.sendIcon {
  transform: rotate(45deg);
  margin-left: 0.2rem;
}

.productAction {
  background: rgba(0, 0, 0, 0.2);
  border: none;

  border-radius: 4px;
  padding: 0.75rem;
  font-size: 1.25rem;

  .productActionText {
    color: white;
    overflow: hidden;
    display: flex;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    transition: 0.2s;
    margin-right: 0;
    width: 0;
    height: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    .productActionText {
      display: flex;
      width: 7rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
}

/* TABS */

li.react-tabs__tab {
  color: var(--Primary-Gray);
  font-family: Articulat CF;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #f0f0f0;
}

li.react-tabs__tab--selected {
  color: var(--Primary-Dark);
  font-weight: 700;
  background: none;

  &:focus:after,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: var(--primary-brand);
    height: 2px;
    width: 2.62rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

.react-tabs__tab-list {
  margin: 0;
}

ul.react-tabs__tab-list {
  border: none;
}
