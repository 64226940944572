@import 'react-toastify/dist/ReactToastify.css';
@import 'react-responsive-carousel/lib/styles/carousel.css';
@import 'react-tabs/style/react-tabs.css';
@import 'react-tooltip/dist/react-tooltip.css';
@import '@/styles/screens.scss';

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  // background-color: var(--background-color);
}

#__next {
  position: relative;
}

#__next,
main,
aside {
  height: 100%;
  overflow: hidden;
}

.pageHeader {
  font-weight: 700;
  font-size: 4rem;
  text-align: left;
  line-height: 4.1rem;
  padding-bottom: 0.5rem;
}

.textOverlap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
.button {
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background-color: transparent;
  border: 1px solid var(--Border);
  box-sizing: border-box;
  outline: none;
  border-style: double;
  text-decoration: none;
  margin: 0;

  font-weight: 500;
  font-size: 0.875rem;
  color: var(--dark-color);

  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }
}

button.primary,
.button.primary {
  background: var(--brand-gradient);
  color: var(--white);
  border-color: transparent;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fillBg {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

@-o-keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

@-moz-keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

@-webkit-keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1rem rgba(#389efc, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(#389efc, 0);
  }
}

.markdownPart {
  -webkit-animation: fadeAnimation 0.3s;
  -moz-animation: fadeAnimation 0.3s;
  -o-animation: fadeAnimation 0.3s;
  animation: fadeAnimation 0.3s;
}

.react-tooltip {
  z-index: 99;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
}

div.signUpAction {
  height: auto;
  max-height: 90vh;
}

.backdropSliderModal {
  display: block;
  @include media('s-screen') {
    display: none;
  }
}

.signUpModal {
  &Content {
    padding: 0;
    background: var(--Light-BG-Gray, #efeae0);
  }

  &Body {
    max-width: 26rem;
  }
}

.affiliateModalContainer {
  // width: fit-content;
}

.affiliateModalBody {
  background: none;
  width: unset;

  & > div {
    padding: 1.4rem 0 0 0;
  }
}

.fullscreenContainerOverlay {
  // left: 0;
  // top: 0;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  width: 32rem;
  border-radius: 1.2rem;

  @include media('s-screen') {
    position: absolute;
    bottom: 0;
    max-height: 70vh;
    border-radius: 1.1rem 1.1rem 0px 0px;
    width: 100%;
    transform: none;
    left: 0;
    top: unset;
    bottom: 0;
    border: none;
    background: var(--Eggshell);
    max-width: 100%;
    height: 100%;
  }
}

.fullscreenContainer {
  border-radius: 24px;
  width: 100%;
  border-radius: 1.1rem;

  @include media('s-screen') {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    transform: none;
    background-color: transparent;
  }
}
