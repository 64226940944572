$s-screen: 700px;
$m-screen: 1024px;
$lg-screen: 1920px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    's-screen': '(max-width: #{$s-screen + 1})',
    // "lg-screen": "(min-width: #{$m-screen + 1}) and (max-width: #{$lg-screen})",
    'm-screen': '(min-width: #{$m-screen + 1})',
    'xl-screen': '(min-width: #{$m-screen + 1})',
  );
  @return map-get($condMap, $c);
}

@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}
