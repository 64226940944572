@import '@/styles/screens.scss';

$common-offset: 1.4rem;

.backdrop {
  background: rgba(46, 46, 46, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99998;

  display: flex;
  justify-content: center;
  align-items: center;

  // position: relative;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99999;
  // max-width: calc(100% - 2rem);
  // width: 32rem;
  width: 100%;
  height: 100%;
  font-family: Articulat CF;
  // border: 4px solid var(--Primary-Dark);
  // background: var(--primary-brand);
}

.body {
  border-radius: 24px;
  // max-width: 32rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0;
  // border: 4px solid var(--Primary-Dark);
  // transform: translate(0.4rem, -0.4rem);

  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  position: relative;
  background-color: var(--Primary-Dark);
  width: 100%;
  padding-top: 3rem;
}

.headerImageContainer {
  display: block;
}

.headerImage {
  width: 100%;
  object-fit: cover;
}

.logoIcon {
  display: flex;
  justify-content: center;
}

.closeIcon {
  position: absolute;
  left: 2rem;
  top: 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.content {
  background: var(--Eggshell, #fbf6ec);
  box-sizing: border-box;
  padding: 1.4rem;

  // border-radius: 18px;
}

.confirmMessage {
  width: 100%;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.3rem;

  text-align: center;
  color: var(--Primary-Dark);
}

.warning {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3rem;

  text-align: center;

  color: var(--Primary-Gray);
  padding: 0.6rem 0 2rem;
}

.buttons {
  display: flex;
  gap: 0.6rem;
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 20.625rem;

  background: var(--Eggshell, #fbf6ec);
  border-radius: 18px;
}

.cancelBtn,
.confirmBtn {
  flex: 1;
}

.confirmBtn {
  border: none;
  color: white;
  border-radius: 6px;
  background: var(--error-red, #ff5b5b);
}

.slideContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  // position: relative;

  .controllerContainer {
    position: absolute;
    top: -1.4rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 2rem;
    display: none;

    @include media('s-screen') {
      display: flex;
    }

    .controller {
      width: 4rem;
      height: 0.5rem;
      background: #efeae0;
      border-radius: 1rem;
    }
  }

  // .slideBody {
  //   // max-height: 70vh;

  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   top: 10rem;
  // }
}
